import React, { Component } from 'react';
export default class Education extends Component {
    render() {
        let resumeData = this.props.resumeData;
        return (
            <div className="row education">
            <div className="three columns header-col">
              <h1><span>Education</span></h1>
            </div>
            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">
                  <h3>{resumeData.school}</h3>
                  <p className="info">{resumeData.degree1.name} <span>•</span> <em className="date">{resumeData.degree1.graduate_time}</em></p>
                  <ul className='project'>
                    <li>{resumeData.degree1.contents.content_1}</li>
                    <li>{resumeData.degree1.contents.content_2}</li>
                  </ul>
                  
                </div>
              </div> {/* item end */}
              
            </div> {/* main-col end */}
          
          </div>
        );
    }
}