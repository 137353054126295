import React, { Component } from 'react';
export default class Professional extends Component {
    render() {
        let resumeData = this.props.resumeData;
        return (
            <div className="row work">
            <div className="three columns header-col">
              <h1><span>Work</span></h1>
            </div>
            <div className="nine columns main-col">
            <div className="row item">
                <div className="twelve columns">
                  <h3>{resumeData.jobs.job_0.company}</h3>
                  <h5>{resumeData.jobs.job_0.location}</h5>
                  <p className="info">{resumeData.jobs.job_0.position} <span>•</span> <em className="date">{resumeData.jobs.job_0.duration}</em></p>
                  
                    <ul className="project text-justify">
                      <li>
                      {resumeData.jobs.job_0.description.description_1}
                      </li>
                      <li>
                      {resumeData.jobs.job_0.description.description_2} 
                      <span className=''> <a className='highlight' target='_blank' href={resumeData.jobs.job_0.description.link}>{resumeData.jobs.job_0.description.link_name}</a></span>
                      {resumeData.jobs.job_0.description.description_21}
                      </li>
                      <li> {resumeData.jobs.job_0.description.description_3}</li>
                      <li> {resumeData.jobs.job_0.description.description_4}</li>
                     
                    </ul>
                   <p className='text-justify'>{resumeData.jobs.job_0.description.description_5}</p>
                  
                </div>
              </div> {/* item end */}
              <div className="row item">
                <div className="twelve columns">
                  <h3>{resumeData.jobs.job_1.company}</h3>
                  <h5>{resumeData.jobs.job_1.location}</h5>
                  <p className="info">{resumeData.jobs.job_1.position} <span>•</span> <em className="date">{resumeData.jobs.job_1.duration}</em></p>
                  <ul className="project text-justify">
                      <li>
                      {resumeData.jobs.job_1.description.description_1}
                      </li>
                      <li>
                      {resumeData.jobs.job_1.description.description_2}
                      </li>
                      <li> {resumeData.jobs.job_1.description.description_3}</li>
                    </ul>
                </div>
              </div> {/* item end */}
              <div className="row item">
                <div className="twelve columns">
                  <h3>{resumeData.jobs.job_2.company}</h3>
                  <h5>{resumeData.jobs.job_2.location}</h5>
                  <p className="info">{resumeData.jobs.job_2.position} <span>•</span> <em className="date">{resumeData.jobs.job_2.duration}</em></p>
                 
                  <ul className="project text-justify">
                      <li>
                      {resumeData.jobs.job_2.description.description_1}
                      </li>
                      <li>
                      {resumeData.jobs.job_2.description.description_2}
                      </li>
                    
                    </ul>
                  
                </div>
              </div> {/* item end */}
              <div className="row item">
                <div className="twelve columns">
                  <h3>{resumeData.jobs.job_3.company}</h3>
                  <h5>{resumeData.jobs.job_3.location}</h5>
                  <p className="info">{resumeData.jobs.job_3.position} <span>•</span> <em className="date">{resumeData.jobs.job_3.duration}</em></p>
                 
                  
                  <ul className="project text-justify">
                      <li>
                      {resumeData.jobs.job_3.description.description_1}
                      </li>
                      <li>
                      {resumeData.jobs.job_3.description.description_2}
                      </li>
                    
                    </ul>
                </div>
              </div> {/* item end */}
              <div className="row item">
                <div className="twelve columns">
                  <h3>Skills</h3>
                  <p className="text-justify">{resumeData.skills.skill_1}</p>
                  <p className="text-justify">{resumeData.skills.skill_2}</p>
                </div>
              </div> {/* item end */}
            </div> {/* main-col end */}
          </div>
        )
    }
}