let resumeData = {
  "imagebaseurl": "http://localhost:3000/",
  "name": "Natalie Nguyen",
  "role": "I'm a web developer. I am passionate about working with JavaScript, and React. I have experience as well with other web technologies such as PHP, HTML, and CSS. I like tackling new challenges, learning new skills and keeping up with the latest technologies",
  "linkedin": "https://www.linkedin.com/in/uyenmai/",
  "github": "https://github.com/uyenntm",
  "school": "San Francisco Bay University",
  "degree1":{
    "name":" Master of Science in Computer Science",
    "graduate_time":"2014 — 2017",
    "contents":{
      "content_1":"GPA 4.0",
      "content_2":"Teacher Assistant for 1 year",
    }
  } 
 
 ,
  "jobs": {
    "job_0": {
      "company": "Decision Ready Solutions",
      "location": "Irvine, CA",
      "position": "Software Enginner",
      "duration": "March 2020 – February 2023",
      "description": {
        "description_1":"Experienced on database development, with a strong background in TSQL and SSIS. Skilled in designing and implementing databases, complex queries, applications, and reports on SQL Server",
        "description_2":"Developed back-end and front-end solutions for the web application of ",
        "link":"http://www.decisionreadysolutions.com/",
        "link_name":"Decision Ready Solutions",
        "description_21":" utilizing technologies such as ASP.NET MVC, Entity Framework, Bootstrap, JQuery, and JavaScript",
        "description_3":"Collaborated with cross-functional teams to design, develop, test, and release new features for Fannie Mae, PennyMac, and FifthThird's fintech applications. Implemented components to retrieve and validate loan information using REST API.",
        "description_4":"Monitored and supported the production application, ensuring seamless functionality and performance",
        "description_5":"Proficient in technologies such as SQL Server, SQL Queries, LINQ, TSQL, SSIS, Linux, ASP.NET MVC, Entity Framework, Json or XML Web Services, Bootstrap, Jquery, JavaScript, Rest API, TFS"

      }
     
    },
    "job_1": {
      "company": "San Francisco Bay University",
      "location": "Fremont, CA",
      "position": "Programming Instructor",
      "duration": "May 2016 – May 2017",
      "description": {
        "description_1":"Taught courses in JavaScript, C, C",
        "description_2":"Conducted lab assignments to provide hands-on experience with the programming languages and data structures",
        "description_3":"Assessed student performance through quizzes, exams, and projects, providing constructive feedback to support their growth",
      }
    },
    "job_2": {
      "company": "Positive Thinking Company APAC",
      "location": "Hochiminh, Vietnam",
      "position": "Web Developer",
      "duration": "March 2011 - October 2013",
     
      "description": {
        "description_1":"Created Ligaris Intranet, an internal blog platform for companies to use for communication and collaboration. Built using WordPress, JavaScript, CSS, and MySQL, the platform streamlined internal communication for businesses.",
        "description_2":"Developed and launched Harlem Wine, a wine discovery tool that leverages a user's current location to suggest nearby wine options and where they can be purchased. Utilized JavaScript, PHP, JQuery, CSS, and MySQL in building the platform",

      }
    },
    "job_3": {
      "company": "Dirox",
      "location": "Hochiminh, Vietnam",
      "position": "Web Developer",
      "duration": "January 2010 – February 2011",
      "projects": {
        "project_1": "Built www.laboratoires-klorane.com which helps users find appropriate Klorane products such as hair conditioners, and hair moisturizers, using Symfony, jQuery, CSS, and MySQL.",
        "project_2": "Built PMS which is a project management system and timesheet for employees to input their daily hours and their daily tasks using Zend, jQuey, CSS, and MySQL"
      },
      "description": {
        "description_1":"Developed the Laboratoires Klorane tool, a platform that assists users in finding the right Klorane hair care products, including conditioners and moisturizers. The platform supports multiple languages and was built using Symfony, JQuery, CSS, and MySQL",
        "description_2":"Designed and implemented Project Management System, a comprehensive project management system and timesheet application for employees. The system enables employees to easily input their daily hours and tasks, streamlining the reporting process. Utilized Zend, JQuery, CSS, and MySQL in the development of the platform",

      }
    }
  }//end jobs
  ,"skills":{
    "skill_1":"Proficient in C#, ASP.NET MVC, Entity Framework, Rest API, Microsoft SQL Server, LINQ, TSQL, SSIS, My SQL, Linux, JavaScript, JQuery, React JS with hooks, HTML, CSS, and Bootstrap",
    "skill_2":"Experienced with the use of various tools including SQL Server Management Studio, Visual Studio, VSCode, TFS, Github, Jira, and Putty"

  }//end skills
  
  
}

  
export default resumeData