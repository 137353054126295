import React, { Component, Profiler } from 'react';
import Education from './education';
import Professional from './professional';
export default class Resume extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <React.Fragment>
      {/*generated code*/}
      <section id="resume">
      <nav id="nav-wrap">
            <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
            <a className="mobile-btn" href="#" title="Hide navigation">Hide navigation</a>
            <ul id="nav" className="nav">
              {/* <li className="current"><a className="smoothscroll" href="#home">Home</a></li> */}
              <li><a className="smoothscroll" href="#about">About</a></li>
              <li><a className="smoothscroll" href="#resume">Resume</a></li>
              <li><a className="smoothscroll" href="#portfolio">Works</a></li>
          
            </ul> {/* end #nav */}
          </nav> {/* end #nav-wrap */}
      
        {/* Work
      ----------------------------------------------- */}
     <Professional resumeData={resumeData}></Professional>
      {/* End Work */}
              {/* Education
      ----------------------------------------------- */}
        <Education resumeData={resumeData}></Education>
         {/* End Education */}
      </section>
      </React.Fragment>
    );
  }
}