import React from 'react';
import './App.css';
import About from './components/about/about';
import Resume from './components/resume/resume';
//import Header from './components/header/header';
import Portfolio from './components/portfolio/portfolio';
// import Testimonials from  './components/testimonials/testimonials';
import Footer from './components/footer/footer';

// Data for components
import resumeData from './data/resumeData';
import aboutData from './data/aboutData';
import portfolioData from './data/portfolioData';
function App() {
  //console.log("resumeData", resumeData);
  return (
    <div className="App">
        {/* <Header resumeData={resumeData}/> */}
        <About aboutData = {aboutData} resumeData={resumeData}/>
        <Resume resumeData={resumeData} />
        <Portfolio portfolioData={portfolioData} />
        {/* <Testimonials /> */}
        <Footer  />
    </div>
  );
}

export default App;
