let portfolioData = {
  
  "projects": {
    "project_1": {
      "name": "Youtube Search",
      "type": "Web Developer",
      "img": "youtube-search.png",
      "url":"https://natalievsmediayoutube.netlify.com/",
      "description": "Vanilla JavaScript and Youtube API to get video information from Youtube server."
    },
    "project_2": {
      "name": "Weather Forecast",
      "type": "Web Developer",
      "img": "weather-app.png",
      "url":"https://natalie-weather.netlify.com/",
      "description": "ReactJS, Bootstrap, Moment and Open Weather API to get weather information from the server."
    },
   
  }
  
  
}

  
export default portfolioData