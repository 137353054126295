import React, { Component } from 'react';
export default class About extends Component {
  render() {
    let aboutData = this.props.aboutData;
    let resumeData = this.props.resumeData;
    return (
      <React.Fragment>
      {/*generated code*/}
      <section id="about">
     
        <div className="row">
          
          <div className=" columns main-col">
            <h1>Welcome!</h1>
            
            
            <h5 className='text-justify'>{aboutData.description}
            </h5>
            
            <div className="row">
           
              
              <div className="columns contact-details">
                <h2>Contact Details</h2>
                <p className="address">
                  <span>{aboutData.name}</span><br />
                  
                 
                  <span><a href={"mailto:"+aboutData.email}>{aboutData.email}</a></span>
                 
                </p>
                <ul className="social">
                <li><a href={resumeData.linkedin} target="_blank"><i className="fa fa-linkedin" /></a></li>
                <li><a href={resumeData.github} target="_blank"><i className="fa fa-github"></i></a></li>
              </ul>
              </div>
              
              <div className="columns download">
                <p>
                  <a href={"files/"+aboutData.resume} className="button" target="_blank"><i className="fa fa-download" />Download Resume</a>
                </p>
              </div>
            </div> {/* end row */}
          </div> {/* end .main-col */}
        </div>
      </section>
      </React.Fragment>
    );
  }
}